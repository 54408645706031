import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ReleasesService } from '@core/_services/releases.service';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit {

  idNew: any;
  new: any;
  urlVideo: any;

  constructor(
      private activatedRoute: ActivatedRoute,
      private releasesServices: ReleasesService,
      private sanitizer: DomSanitizer,
      private router: Router
    ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(routeParams => {
      this.releasesServices.getNewDetail(routeParams.id).subscribe((data: any) => {
        this.new = data;
        this.urlVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.new.youtube_url);
      });
    });
  }

  prev(id: any) {
    this.new = null;
    this.router.navigate([`/new/${id}`]);
  }

  next(id: any) {
    this.new = null;
    this.router.navigate([`/new/${id}`]);
  }

}
