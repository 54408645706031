import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AgenciesService } from '../services/agencies/agencies.service';
import { CartService } from '../services/cart/cart.service';
import { ProductsService } from '../services/products/products.service';
import { ImagesService } from '../services/images/images.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {

  totalPrice: any;
  cartProducts: any;
  cartTotal: any;
  name: any;
  email: any;
  phone: any;
  agencyData: any;
  agencyId: any;
  banner: any;
  urlDev: any;

  constructor(
      private productsService: ProductsService,
      private router: Router,
      private agencyServices: AgenciesService,
      private cartServices: CartService,
      private imgService: ImagesService
    ) { }

  ngOnInit(): void {

    this.urlDev = environment.stgUrl;

    this.cartServices.currentItems.subscribe((data: any) => {
      this.cartProducts = data.cart;
      this.cartTotal = data.total;
    });

    const localData: any = localStorage.getItem('user');
    const user = JSON.parse(localData);
    this.name = user.name;
    this.email = user.email;
    this.phone = user.phone;

    this.agencyId = localStorage.getItem('idAgency');

    this.agencyServices.getAgency(this.agencyId).subscribe((data: any) => {
      this.agencyData = data;
    });

    this.imgService.getImages().subscribe((data: any) => {
      this.banner = data;
    });

  }

  saveCart() {
    this.agencyId = localStorage.getItem('idAgency');

    const data = {
      agency : this.agencyId
    }
    this.productsService.saveCart(data).subscribe((data: any) => {
      this.router.navigate([`/pop/successfulorder/${data.id}`]);
    });
  }

  deleteItemCart(rowId: any) {
    this.productsService.deleteCardItem(rowId).subscribe((data: any) => {
      console.log('producto eliminado');

      this.productsService.getCart().subscribe((data: any) => {
        //
      });

      this.cartServices.currentItems.subscribe((data: any) => {
        this.cartProducts = data.cart;
        this.cartTotal = data.total;
      });
    });
  }

}
