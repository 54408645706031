import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@core/_helpers/auth.guard';
import { DetailOrderComponent } from './detail-order/detail-order.component';
import { HomePopComponent } from './home-pop/home-pop.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { NewOrderComponent } from './new-order/new-order.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { PopComponent } from './pop.component';
import { ProfilePopComponent } from './profile-pop/profile-pop.component';
import { SuccessfulOrderComponent } from './successful-order/successful-order.component';
import { VerifyCartComponent } from './verify-cart/verify-cart.component';

const routesPop: Routes = [

    // pop routes
  {
    path: '',
    component: PopComponent,
    children: [
        { path: '', component: ProfilePopComponent, canActivate: [AuthGuard], data: {expetedGroup: 'pop'}},
        { path: 'home', component: HomePopComponent, canActivate: [AuthGuard], data: {expetedGroup: 'pop'}},
        { path: 'myprofile', component: ProfilePopComponent, canActivate: [AuthGuard], data: {expetedGroup: 'pop'}},
        { path: 'myorders', component: MyOrdersComponent, canActivate: [AuthGuard], data: {expetedGroup: 'pop'}},
        { path: 'neworder', component: NewOrderComponent, canActivate: [AuthGuard], data: {expetedGroup: 'pop'}},
        { path: 'neworder/:idAgency', component: NewOrderComponent, canActivate: [AuthGuard], data: {expetedGroup: 'pop'}},
        { path: 'verifycart', component: VerifyCartComponent, canActivate: [AuthGuard], data: {expetedGroup: 'pop'}},
        { path: 'orderDetail/:id', component: DetailOrderComponent, canActivate: [AuthGuard], data: {expetedGroup: 'pop'}},
        { path: 'summaryorder', component: OrderSummaryComponent, canActivate: [AuthGuard], data: {expetedGroup: 'pop'}},
        { path: 'successfulorder/:id', component: SuccessfulOrderComponent, canActivate: [AuthGuard], data: {expetedGroup: 'pop'}}
    ]
  },

   // toolkit routes
  {
    path: 'mdm',
    loadChildren: () => import('../toolkit/toolkit.module').then(m => m.ToolkitModule)
  },

];

@NgModule({
    imports: [RouterModule.forChild(routesPop)],
    exports: [RouterModule]
})

export class PopRoutingModule { }
