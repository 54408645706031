import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/_services/authentication.service';


@Injectable({providedIn: 'root'})
export class PermissionsService {
    constructor(
        private authenticationService: AuthenticationService
    ) { }

    canGo(module: string) {
        const currentUser = this.authenticationService.currentUserValue;
        if(currentUser !== null && currentUser.groups) {
            return currentUser.groups.indexOf(module) >= 0;
        }
    }

}