import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private http: HttpClient) { }

  getOrders() {
    return this.http.get(`${environment.stgUrl}/api/order`);
  }

  getOrderById(orderId: any) {
    return this.http.get(`${environment.stgUrl}/api/order/${orderId}`);
  }
}
