import { group } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/_services/authentication.service';
import { ImagesService } from '@core/_services/images.service';
import { ProfileService } from '@core/_services/profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  user: any;
  stateForm: boolean = true;
  banner: any;
  url: any;
  userType: string;
  canSee: boolean;

  historyForm = new FormGroup({
    history: new FormControl('', Validators.required)
  });

  constructor(
      private authService: AuthenticationService,
      private router: Router,
      private profileService: ProfileService,
      private imgService: ImagesService
    ) { }

  ngOnInit(): void {
    this.authService.currentUser.subscribe((data: any) => {
      this.user = data;
    });
    this.imgService.getImages().subscribe((data: any) => {
      this.banner = data;
    });

    let data = localStorage.getItem('user');
    let groups = JSON.parse(data).groups;

    groups.forEach((e: any) => {
      if (e === 'intranet' || e === 'manual_de_marca' || e === 'toolkit') {
        this.userType = 'user'
        this.canSee = true;
      } else if (e === 'manual_de_marca_admin' || e === 'toolkit_admin') {
        this.userType = 'admin'
        this.canSee = true;
      } else {
        this.canSee = false;
      }
    });

    this.ifAdminMdm();
    this.ifUserMdm();
  }

  ifAdminMdm() {
    if(this.userType === 'admin') {
      this.url = 'admin'
    }
  }

  ifUserMdm() {
    if(this.userType === 'user') {
      this.url = 'usuario'
    }
  }

  logOut() {
    this.authService.logout();
    this.router.navigate(['/']);
  }

  showForm() {
    this.stateForm = !this.stateForm;
  }

  sendHistory() {
    this.profileService.updateHistory(this.historyForm.value).subscribe((data: any) => {
      data.groups = this.authService.currentUserValue.groups;
      data.token = this.authService.currentUserValue.token;
      this.authService.updateLocalInfoUser(data, data.groups);
    });
    this.historyForm.reset();
    this.stateForm = !this.stateForm;
  }

}
