import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionsService } from '@core/_helpers/permissions.service';
import { AuthenticationService } from '@core/_services/authentication.service';
import { MenuService } from '@core/_services/menu.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public menu: any
  public userActive: boolean = false
  public accessActive: boolean = false

  user: any;
  userType: string;
  url: any;
  canSee: boolean;

  public stateMenu: boolean = false;

  constructor(
    private menuService: MenuService,
    private authService: AuthenticationService,
    private router: Router,
    private permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.authService.currentUser.subscribe((data: any) => {
      this.user = data;
    });
    // this.getMenu()

    let data = localStorage.getItem('user');
    let groups = JSON.parse(data).groups;

    groups.forEach((e: any) => {
      if (e === 'intranet' || e === 'manual_de_marca' || e === 'toolkit') {
        this.userType = 'user'
        this.canSee = true;
      } else if (e === 'manual_de_marca_admin' || e === 'toolkit_admin') {
        this.userType = 'admin'
        this.canSee = true;
      } else {
        this.canSee = false;
      }
    });

    this.ifAdminMdm();
    this.ifUserMdm();
  }

  ifAdminMdm() {
    if(this.userType === 'admin') {
      this.url = 'admin'
    }
  }

  ifUserMdm() {
    if(this.userType === 'user') {
      this.url = 'usuario'
    }
  }

  showItem($this) {
    //
  }

  accsessActiveF() {
    this.accessActive = !this.accessActive;
    const that = this;

    window.onclick = function(event: any) {

      if(!event.target.matches('.user-access')){
        that.accessActive = false;
      }
    }

  }

  userActiveF() {
    this.userActive = !this.userActive;
    const that = this;

    window.onclick = function(event: any) {

      if(!event.target.matches('.user-name')){
        that.userActive = false;
      }
    }

  }

  openMenu(id) {
    var element = document.getElementById(id)
    if(element.classList.contains("open-menu")){
      element.classList.remove("open-menu")
    } else {
      element.classList.add("open-menu")
    }
  }

  openSidenav() {
    this.stateMenu = !this.stateMenu;
  }

  canGoPop() {
    return this.permissionsService.canGo('pop');
  }

  logOut() {
    this.authService.logout();
    this.router.navigate(['/']);
  }

}
