import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import { User } from '@core/_models/user';
import { environment } from '@env/environment';


@Injectable({providedIn: 'root'})
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public token: string;
    public language: string;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.token = localStorage.getItem('token');
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public get currentToken(): string {
        return this.currentUserSubject.value ? this.currentUserSubject.value.token : '';
    }

    updateLocalInfoUser(user, groups) {
        groups = groups || null;
        if (groups !== null) {
            user.groups = groups;
        } else {
            user.groups = this.currentUserValue.groups;
        }
        localStorage.setItem('user', JSON.stringify(user));
        this.currentUserSubject.next(user);
    }

    login(email: string, password: string ) {
        return this.http.post<any>(`${environment.stgUrl}api/login`, {email, password})
            .pipe(map(response => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                const user = response.user;
                user.groups = response.access;
                user.token = response.token;
                localStorage.setItem('currentUser', JSON.stringify(user));
                localStorage.setItem('token', user.token);
                this.currentUserSubject.next(user);
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        this.currentUserSubject.next(null);
    }
}