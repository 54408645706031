import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ReleasesService {

  constructor(private http: HttpClient) { }

  getDate() {
    return this.http.get(`${environment.stgUrl}/api/dates`);
  }

  getLatestReleases() {
    return this.http.get(`${environment.stgUrl}/api/releases`);
  }

  getReleases(year: number) {
    return this.http.get(`${environment.stgUrl}/api/releases?year=${year}`);
  }

  getReleasesMonth(year: number, month: number) {
    return this.http.get(`${environment.stgUrl}/api/releases?year=${year}&month=${month}`);
  }

  getReleaseHome(year: number, home: number) {
    return this.http.get(`${environment.stgUrl}/api/releases?year=${year}&home=${home}`);
  }

  getMonthsReleases(year: number) {
    return this.http.get(`${environment.stgUrl}/api/months/releases?year=${year}`,);
  }

  getMonths(year: number) {
    return this.http.get(`${environment.stgUrl}/api/months/newsletters?year=${year}`,);
  }

  getLatestNews() {
    return this.http.get(`${environment.stgUrl}/api/newsletters`);
  }

  getNews(year: number) {
    return this.http.get(`${environment.stgUrl}/api/newsletters?year=${year}`);
  }

  getNewsByMonth(year: number, month: number) {
    return this.http.get(`${environment.stgUrl}/api/newsletters?year=${year}&month=${month}`);
  }

  getNewDetail(id: number) {
    return this.http.get(`${environment.stgUrl}/api/newsletters/${id}`);
  }
}
