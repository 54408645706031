import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgenciesService } from '../services/agencies/agencies.service';
import { OrdersService } from '../services/orders/orders.service';
import { ImagesService } from '../services/images/images.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-detail-order',
  templateUrl: './detail-order.component.html',
  styleUrls: ['./detail-order.component.scss']
})
export class DetailOrderComponent implements OnInit {

  orderId: number;
  orderData: any;
  name: any;
  email: any;
  phone: any;
  agencyData: any;
  cartTotal: 20.000;
  banner: any;
  urlDev: any;

  constructor(private ordersServices: OrdersService, private route: ActivatedRoute, private agencyServices: AgenciesService, private imgService: ImagesService) { }

  ngOnInit(): void {
    this.urlDev = environment.stgUrl;

    this.route.params.subscribe(params => {
      this.orderId = params['id'];
    });

    this.ordersServices.getOrderById(this.orderId).subscribe((data: any) => {
      this.orderData = data;
    });

    const localData: any = localStorage.getItem('user');
    const user = JSON.parse(localData);
    this.name = user.name;
    this.email = user.email;
    this.phone = user.phone;

    this.imgService.getImages().subscribe((data: any) => {
      this.banner = data;
    });
  }

}
