import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CartService } from '../services/cart/cart.service';
import { ProductsService } from '../services/products/products.service';
import { ImagesService } from '../services/images/images.service';
import Swal from 'sweetalert2'
import { environment } from '@env/environment';

@Component({
  selector: 'app-verify-cart',
  templateUrl: './verify-cart.component.html',
  styleUrls: ['./verify-cart.component.scss']
})
export class VerifyCartComponent implements OnInit {

  optionForm = new FormGroup({
    size: new FormControl(''),
    quantity: new FormControl('')
  });

  cartProducts: any;
  cartTotal: any;
  agencyId: any;
  edit = false;
  productToEdit: any;
  totalPrice: any;
  banner: any;
  disabledBtn: boolean;
  urlDev: any;

  constructor(private cartServices: CartService, private productsService: ProductsService, private router: Router, private imgService: ImagesService) { }

  ngOnInit(): void {

    this.urlDev = environment.stgUrl;

    this.agencyId = localStorage.getItem('idAgency');

    this.cartServices.currentItems.subscribe((data: any) => {
      this.cartProducts = data.cart;
      this.cartTotal = data.total;
      if (!this.cartProducts) {
        this.disabledBtn = false;
      } else {
        this.disabledBtn = true;
      }
    });
    
    this.imgService.getImages().subscribe((data: any) => {
      this.banner = data;
    });
  }

  saveCart() {
    this.router.navigate([`/pop/summaryorder`]);
  }

  deleteItemCart(item: any) {
    Swal.fire({
      html: `<b>¿Estás seguro de querer eliminar ${item.name}?</b>`,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: '<i class="fa fa-trash-alt"></i> ELIMINAR',
      confirmButtonAriaLabel: 'Thumbs down'
    }).then((result) => {
      if(!result.isDismissed) {
        this.productsService.deleteCardItem(item.rowId).subscribe((data: any) => {
          console.log('producto eliminado');
    
          this.productsService.getCart().subscribe((data: any) => {
            //
          });

          this.cartServices.currentItems.subscribe((data: any) => {
            this.cartProducts = data.cart;
            this.cartTotal = data.total;
            if (this.cartProducts.length === 0) {
              this.disabledBtn = false;
            } else {
              this.disabledBtn = true;
            }
          });
        });
      }
    });
  }

  editProduct(productId: any) {
    this.edit = true;
    this.productsService.getProductById(productId).subscribe((data: any) => {
      this.productToEdit = data;
    });
  }

  goNewOrder() {
    this.router.navigate([`/pop/neworder`]);
  }

  goCart(idItem: number, rowId: any, item: any) {

    this.productsService.deleteCardItem(rowId).subscribe((data: any) => {
      console.log('producto eliminado');

      this.productsService.getCart().subscribe((data: any) => {
        //
      });

      this.cartServices.currentItems.subscribe((data: any) => {
        this.cartProducts = data.cart;
        this.cartTotal = data.total;
        if (!this.cartProducts) {
          this.disabledBtn = false;
        } else {
          this.disabledBtn = true;
        }
      });
    });

    const optionValues = this.optionForm.value;
    const qty = optionValues.quantity;
    const size = optionValues.size;

    const data = {
      qty,
      options: {
        size
      }
    }

    setTimeout(() => {
      this.productsService.putItemCart(data, idItem).subscribe((data: any) => {
        console.log('producto añadido');
        setTimeout(() => {
          this.optionForm.reset();
        }, 1000);
  
        this.productsService.getCart().subscribe((data: any) => {
          //
        });
  
        this.cartServices.currentItems.subscribe((data: any) => {
          this.cartProducts = data.cart;
          this.cartTotal = data.total;
        });
  
        this.totalPrice = {}
        this.edit = false;
      });
    }, 500);

  }

}
