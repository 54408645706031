import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { AgenciesService } from '../services/agencies/agencies.service';
import { CartService } from '../services/cart/cart.service';
import { ProductsService } from '../services/products/products.service';
import { CustomselectComponent } from '../shared/customselect/customselect.component';

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.scss']
})
export class NewOrderComponent implements OnInit, AfterViewInit {

  public colors = [
    {value: "red", name: "Red"},
    {value: "blu", name: "Blue"},
    {value: "gre", name: "Green"},
    {value: "bla", name: "Black"},
    {value: "yel", name: "Yellow"}
  ];

  products: any;
  totalPrice: any;
  cartProducts: any;
  cartTotal: any;
  agencies: any;
  isAgency: boolean = false;
  unitPrice: any;
  productId: any;
  idAgency: any;
  agency: any;
  actionInfo: any;
  idInfo: any;
  showAlert: boolean = false;
  urlDev: any;
  // alreadyClicked: Boolean = true;

  agencyForm = new FormGroup({
    idAgency: new FormControl('')
  });

  optionForm = new FormGroup({
    size: new FormControl(''),
    quantity: new FormControl('')
  });

  constructor(
        private productsService: ProductsService,
        private router: Router,
        private agencyServices: AgenciesService,
        private cartServices: CartService,
        private route: ActivatedRoute
      ) {
    this.totalPrice = {};
  }

  ngOnInit(): void {

    this.urlDev = environment.stgUrl;

    this.route.params.subscribe((params: any) => {
      this.idAgency = params.idAgency;
    });

    this.agencyForm.controls.idAgency.setValue(this.idAgency);

    if (this.idAgency === '' || this.idAgency === null || this.idAgency === undefined) {
      this.isAgency = false;
    } else {
      this.isAgency = true;
    }

    this.agencyServices.getAllAgencies().subscribe((data: any) => {
      this.agencies = data;
      this.agencies.forEach((element: any) => {
        if (element.id == this.idAgency) {
          this.agency = element.distributor;
          console.log(this.agency);
        }
      });
    });

    this.productsService.getProducts().subscribe((data: any) => {
      this.products = data.data;
    });

    this.productsService.getCart().subscribe((data: any) => {
      //
    });

    this.cartServices.currentItems.subscribe((data: any) => {
      this.cartProducts = data.cart;
      this.cartTotal = data.total;
    });

  }

  getDataInput(event: any) : void {
    this.unitPrice = event.target.dataset['unitprice'];
    this.productId = event.target.dataset['id'];
  }

  ngAfterViewInit(): void {
    this.optionForm.controls.quantity.valueChanges.subscribe((value: any) => {

      (value !== null)
        ? this.setSubtotal(value * this.unitPrice, this.productId)
        : this.setSubtotal(0, this.productId);

    });
  }

  getSubtotal(id: number) {
    const property = 'id_' + id;
    if (property in this.totalPrice) {
      return this.totalPrice['id_' + id];
    } else {
      return 0;
    }
  }

  setSubtotal(value: number, id: number) {
    this.totalPrice['id_' + id] = value;
  }

  goCart(idItem: number, item: any) {
    const optionValues = this.optionForm.value;
    const qty = optionValues.quantity;
    const size = optionValues.size;

    const data = {
      qty,
      options: {
        size
      }
    }

    this.productsService.putItemCart(data, idItem).subscribe((data: any) => {
      console.log('producto añadido');
      setTimeout(() => {
        this.optionForm.reset();
      }, 1000);

      this.productsService.getCart().subscribe((data: any) => {
        //
      });

      this.cartServices.currentItems.subscribe((data: any) => {
        this.cartProducts = data.cart;
        this.cartTotal = data.total;
      });

      this.totalPrice = {}
    });

  }

  deleteItemCart(rowId: any) {
    this.productsService.deleteCardItem(rowId).subscribe((data: any) => {
      console.log('producto eliminado');

      this.productsService.getCart().subscribe((data: any) => {
        //
      });

      this.cartServices.currentItems.subscribe((data: any) => {
        this.cartProducts = data.cart;
        this.cartTotal = data.total;
      });
    });
  }

  onChange(event: any) {
    const dataAgency = this.agencyForm.value;

    console.log(this.agencyForm.value);

    this.agencyServices.getAllAgencies().subscribe((data: any) => {
      this.agencies.forEach((element: any) => {
        if (element.id == dataAgency.idAgency) {
          this.agency = element.distributor;
        }
      });
    });

    if(dataAgency.idAgency !== '') {
      this.isAgency = true;
    }
  }

  goToSummary() {
    console.log(this.isAgency);
    if (!this.isAgency){
      console.log('no ir');
      this.showAlert = true;
    } else {
      this.router.navigate(['/pop/verifycart']);
      const data = this.agencyForm.value;
      const idAgency = data.idAgency;
      if (idAgency === '') {
        localStorage.setItem('idAgency', this.idAgency);
      } else {
        localStorage.setItem('idAgency', idAgency);
      }
    }
  }

  showInfo(id: any) {
    this.idInfo = id;
  }

  hideInfo() {
    this.idInfo = null;
  }

}
