import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AgenciesService } from '../services/agencies/agencies.service';
import { ImagesService } from '../services/images/images.service';
import { ProfileService } from '../services/profile/profile.service';

@Component({
  selector: 'app-profile-pop',
  templateUrl: './profile-pop.component.html',
  styleUrls: ['./profile-pop.component.scss']
})
export class ProfilePopComponent implements OnInit {

  stateForm2: boolean = true;
  responseInfo: boolean = false;
  agencyData: any;
  banner: any;

  profileForm = new FormGroup({
    distributor: new FormControl({value: '', disabled: true}),
    nit: new FormControl({value: '', disabled: true}),
    name: new FormControl({value: '', disabled: true}),
    address: new FormControl({value: '', disabled: true}),
    city: new FormControl({value: '', disabled: true}),
    phone: new FormControl({value: '', disabled: true})
  });

  profileForm2 = new FormGroup({
    distributor: new FormControl('', Validators.required),
    nit: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required)
  });

  informationForm = new FormGroup({
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.email, Validators.required]),
    phone: new FormControl('', Validators.required)
  });

  constructor(private agencyServices: AgenciesService, private profileService: ProfileService, private imgService: ImagesService) { }

  ngOnInit(): void {
    this.agencyServices.getAllAgencies().subscribe((data: any) => {
      this.agencyData = data;
      
      const last = data[data.length - 1];

      this.profileForm.patchValue({
        distributor: last.distributor,
        nit: last.nit,
        name: last.name,
        address: last.address,
        city: last.city,
        phone: last.phone
      });
    });
    this.imgService.getImages().subscribe((data: any) => {
      this.banner = data;
    });
  }

  sendData() {
    this.profileService.addAgency(this.profileForm2.value).subscribe((data: any) =>{

      this.profileForm.patchValue({
        distributor: data.distributor,
        nit: data.nit,
        name: data.name,
        address: data.address,
        city: data.city,
        phone: data.phone
      });

      this.profileForm2.reset();
    });
    this.stateForm2 = !this.stateForm2;
  }

  showForm() {
    this.stateForm2 = !this.stateForm2;
  }

  sendInformation() {
    this.profileService.addProfileData(this.informationForm.value).subscribe((data: any) => {
      console.log('datos enviados');
      this.informationForm.reset();
    });

    this.responseInfo = true;
    setTimeout(() => {
      this.responseInfo = false;
    }, 7000);
  }

}
