import { Component, OnInit } from '@angular/core';
import { ProvidersService } from '../services/providers/providers.service';

const CLASSBODYMODAL = "open_modal";

@Component({
  selector: 'app-floating-btn',
  templateUrl: './floating-btn.component.html',
  styleUrls: ['./floating-btn.component.scss']
})
export class FloatingBtnComponent implements OnInit {

  public modalInfo:boolean = false;
  providers: any;

  constructor(private providersServices: ProvidersService) { }

  ngOnInit(): void {
    this.providersServices.getProviders().subscribe((data: any)=> {
      this.providers = data.data;
    });
  }

  openModal() {
    this.modalInfo = true;
    document.body.classList.add(CLASSBODYMODAL);
  }

  closeModal($event:boolean) {
    this.modalInfo = $event;
    document.body.classList.remove(CLASSBODYMODAL);
  }

}
