import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import {pipe} from 'rxjs';
import { CartService } from '../cart/cart.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient, private cartService: CartService) { }

  getProducts() {
    return this.http.get(`${environment.stgUrl}/api/product`);
  }

  putItemCart(body: any, idProduct: number) {
    return this.http.put(`${environment.stgUrl}/api/cart/${idProduct}`, body);
  }

  getCart() {
    return this.http.get(`${environment.stgUrl}/api/cart`)
    .pipe(map((response: any) => {
      this.cartService.updateCartItems(response);
    }));
  }

  deleteCardItem(rowId: any) {
    return this.http.delete(`${environment.stgUrl}/api/cart/${rowId}`);
  }

  saveCart(body: any) {
    return this.http.post(`${environment.stgUrl}/api/cart`, body);
  }

  getProductById(id: any) {
    return this.http.get(`${environment.stgUrl}/api/product/${id}?withRowId`);
  }
}
