import { Component, OnInit } from '@angular/core';
import { HomeService } from '@core/_services/home.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  data: any;

  constructor(private homeServices: HomeService) { }

  ngOnInit(): void {
    this.homeServices.getHomeData().subscribe((data: any) => {
      this.data = data;
    });
  }

}
