import { Component, OnInit, Input, ElementRef, ChangeDetectorRef, AfterViewInit, AfterContentChecked, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { SelectItems } from './customselect'

@Component({
  selector: 'app-customselect',
  templateUrl: './customselect.component.html',
  styleUrls: ['./customselect.component.scss']
})
export class CustomselectComponent implements AfterViewInit, AfterContentChecked {

  @ViewChild('selectCustom') selectCustom:ElementRef;

  @Input("show") show:string;
  @Input("noSelected") noSelected:string;
  @Input("optionalText") optionalText:string = "Opcional";

  @Output("parentClick") parentClick: EventEmitter<any> = new EventEmitter();

  public disabled:boolean;
  public change:boolean = false;
  public optional:boolean;
  public status_select:boolean = false;
  public select_label:string = "";
  public select_name:string = "";
  public select_items = [];
  public selected:number;

  private options: any;
  private s:any;
  private items = [];

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if(!this.elt.nativeElement.contains(event.target)) {
      this.status_select = false;
    }
  }

  constructor(private elt: ElementRef, private cdref: ChangeDetectorRef) { }

  ngAfterContentChecked():void {
    this.elementSelect();
    this.buildSelectItems();
  }

  ngAfterViewInit(): void {
    this.elementSelect();
    this.buildSelectItems();

    // Values in component
    if (this.options.labels.length >= 1) {
      this.select_label = this.options.labels[0].innerText;
    }

    this.optional = !this.options.required;
    this.disabled = this.options.disabled;

    this.selected = this.options.selectedIndex;
    if (typeof(this.noSelected) !== "undefined") {
      this.selected = -1;
    }

    if (typeof(this.show) !== "undefined") {
      this.change = true;
    }

    // Is necesarry
    this.cdref.detectChanges();
  }

  private elementSelect() {
    this.s = this.elt.nativeElement;
    this.options = this.s.children[0].children[0];
  }

  private buildSelectItems() {
    this.items = [];

    // add Items
    for (let item of this.options) {
      if (item.value !== "" && item.text !== "") {
        this.items.push(new SelectItems(item.value, item.text, item.index));
      }
    }

    if(JSON.stringify(this.items) !== JSON.stringify(this.select_items)) {
      this.select_items = this.items;
    }
  }

  public changeValue(item_e: any) {
    this.options.selectedIndex = this.selected = item_e.index;
    this.select_name = (typeof(this.options.selectedOptions[0]) === "undefined") ? "" : this.options.selectedOptions[0].text ;

    this.options.dispatchEvent(new CustomEvent('change', { bubbles: true }));
    this.options.dispatchEvent(new CustomEvent('blur', { bubbles: true }));

    this.status_select = false;
    this.change = true;
  }

  public showSelect() {
    if (!this.disabled) {
      this.status_select = !this.status_select;
    }
  }

}
