import { Component, OnInit } from '@angular/core';
import { OrdersService } from '../services/orders/orders.service';
import { ImagesService } from '../services/images/images.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent implements OnInit {

  orders: any;
  banner: any;
  urlDev: any;

  constructor(private ordersServices: OrdersService, private imgService: ImagesService) { }

  ngOnInit(): void {
    this.urlDev = environment.stgUrl;
    this.ordersServices.getOrders().subscribe((data: any) =>{
      this.orders = data.data;
    });
    this.imgService.getImages().subscribe((data: any) => {
      this.banner = data;
    });
  }

}
