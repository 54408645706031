import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ImagesService } from '@core/_services/images.service';
import { ReleasesService } from '@core/_services/releases.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-releases',
  templateUrl: './releases.component.html',
  styleUrls: ['./releases.component.scss']
})
export class ReleasesComponent implements OnInit {

  months = [
    {name: 'Enero', id: 1},
    {name: 'Febrero', id: 2},
    {name: 'Marzo', id: 3},
    {name: 'Abril', id: 4},
    {name: 'Mayo', id: 5},
    {name: 'Junio', id: 6},
    {name: 'Julio', id: 7},
    {name: 'Agosto', id: 8},
    {name: 'Septiembre', id: 9},
    {name: 'Octubre', id: 10},
    {name: 'Noviembre', id: 11},
    {name: 'Diciembre', id: 12}
  ]

  today = new Date();
  year = this.today.getFullYear();
  yearsIn: any = [];
  years: [] = [];
  releases: [] = [];
  banner: any;
  monthsFB: any;
  urlEnv = environment.stgUrl;
  releasesYears: string[] = [];
  releasesMonths: string[] = [];
  sessionReleases: any;
  dates: any;

  filterForm = new FormGroup({
    year: new FormControl(),
    month: new FormControl()
  });

  constructor(private releasesServices: ReleasesService, private imgService: ImagesService) {}

  ngOnInit() {
    this.releasesServices.getDate().subscribe((data: any) => {
      this.dates = data.releases;
      this.dates.forEach((data: any) => {
        this.releasesYears.push(data.year);
      });
    });
    this.releasesServices.getLatestReleases().subscribe((data: any) => {
      this.releases = data;
    });
    this.imgService.getImages().subscribe((data: any) => {
      this.banner = data;
    });
  }

  getReleasesMont(month: number, event: any) {
    // console.dir(document.getElementsByClassName('month'));
    let months = Array.from(document.getElementsByClassName('month'));
    months.forEach((element: any) => {
      element.classList.remove('active');
    });
    this.releasesServices.getReleasesMonth(this.year, month).subscribe((data: any) => {
      this.releases = data;
      event.target.parentNode.parentNode.classList.add('active');
    });
  }

  onChangeYear(event: any){
    this.year = this.filterForm.get('year').value;
    this.releasesServices.getReleases(this.year).subscribe((data: any) => {
      this.releases = data;
    });
    this.dates.forEach((data: any) => {
      if (data.year == this.year) {
        this.releasesMonths = data.months;
      }
    });
  }

  onChangeMonth(event: any) {
    this.releasesServices.getReleasesMonth(this.year, this.filterForm.get('month').value).subscribe((data: any) => {
      this.releases = data;
    });
  }

}
