import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AgenciesService {

  constructor(private http: HttpClient) { }

  getAllAgencies() {
    return this.http.get(`${environment.stgUrl}/api/profile/shipping`);
  }

  getAgency(agencyId: any) {
    return this.http.get(`${environment.stgUrl}/api/profile/shipping/${agencyId}`);
  }
  
}
