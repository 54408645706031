import { Component, OnInit } from '@angular/core';
import {first} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { FormService } from '@core/_services/form.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/_services/authentication.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  public loginForm: FormGroup
  public recoveryForm: FormGroup
  public error: any
  public loading: boolean = false
  public thankYou: boolean = false
  public formSwiper: boolean = true

  constructor(
    private formBuilder: FormBuilder,
    private formService: FormService,
    private router: Router,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.createForm()
  }

  createForm(){
    this.loginForm = this.formBuilder.group({
      loginEmail: ['', [Validators.required, Validators.email, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      password: ['', [Validators.required, Validators.pattern('^[A-Za-z\ \ñäáàëéèíìöóòúùÄÁÀËÉÈÍÌÖÓÒÚÙÑñ \0-9]+'), Validators.minLength(3), Validators.maxLength(50)]]
    });
    this.recoveryForm = this.formBuilder.group({
      recoverEmail: ['', [Validators.required, Validators.email, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]]
    });
  }

  login() {
    this.loginForm.disable()
    this.loading = true
    this.formService.postContactForm(this.loginForm.value)
      .pipe(first())
      .subscribe((data:any) => {
            this.loginForm.reset()
            this.loginForm.enable()
            this.loading = false
            this.thankYou = true
        },
        err => {
          if (err.status === 422) {
            this.error = err.error.message;
            console.log(this.error)
          } else {
            this.error = err.error.message;
            console.log(this.error)
          }
        });
  }

  recover() {
    this.recoveryForm.disable()
    this.loading = true
    this.formService.postContactForm(this.recoveryForm.value)
      .pipe(first())
      .subscribe((data:any) => {
            this.recoveryForm.reset()
            this.recoveryForm.enable()
            this.loading = false
            this.thankYou = true
        },
        err => {
          if (err.status === 422) {
            this.error = err.error.message;
            console.log(this.error)
          } else {
            this.error = err.error.message;
            console.log(this.error)
          }
        });
  }

  changeForm() {
    this.createForm()
  }

  login365() {

    const popup = window.open(environment.stgUrl + '/api/login/microsoft', "login office 365", 'width=800, height=600');

    const me = this;

    window.addEventListener('message', function (data: any) {
      if (data.data.user) {
        const user = data.data.user;
        user['token'] = data.data.token;
        localStorage.setItem('token', user.token);
        me.authService.updateLocalInfoUser(user, data.data.access);
        popup.close();
        if (data.data.access.indexOf('intranet') >= 0) {
          me.router.navigate(['/inicio']);
        } else if (data.data.access.indexOf('pop') >= 0) {
          me.router.navigate(['/pop/myprofile']);
        } else {
          me.router.navigate(['/']);
        }
      }
    });

  }

}
