import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { NewsDetailComponent } from './pages/news-detail/news-detail.component';
import { NewsComponent } from './pages/news/news.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ReleasesComponent } from './pages/releases/releases.component';
import { AuthGuard } from './_helpers/auth.guard';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'ingreso', component: LoginComponent },
  { path: 'inicio', component: HomeComponent, canActivate: [AuthGuard], data: {expetedGroup: 'intranet'} },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard], data: {expetedGroup: 'intranet'} },
  { path: 'releases', component: ReleasesComponent, canActivate: [AuthGuard], data: {expetedGroup: 'intranet'} },
  { path: 'news', component: NewsComponent, canActivate: [AuthGuard], data: {expetedGroup: 'intranet'} },
  { path: 'new/:id', component: NewsDetailComponent, canActivate: [AuthGuard], data: {expetedGroup: 'intranet'} },

  // pop routes
  {
    path: 'pop',
    loadChildren: () => import('./pop/pop.module').then(m => m.PopModule)
  },

  // toolkit routes
  {
    path: 'mdm',
    loadChildren: () => import('./toolkit/toolkit.module').then(m => m.ToolkitModule)
  },
  
  // { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '', pathMatch: 'full' }, //404
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
