import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private currentItemsSubject: BehaviorSubject<any>;
  public currentItems: Observable<any>;

  constructor() {
    this.currentItemsSubject = new BehaviorSubject<any>({qty: 0, items: []})
    this.currentItems = this.currentItemsSubject.asObservable();
  }

  public get currentItemsValue(): any {
    return this.currentItemsSubject.value;
  }

  updateCartItems(items: any) {
    this.currentItemsSubject.next(items);
  }
}
