import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@core/_services/authentication.service';
import { PermissionsService } from './permissions.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private permissionService: PermissionsService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUser;
        let canYouGo = false;
        if (route.data && route.data.expetedGroup) {
            const expetedGroup = route.data.expetedGroup;
            canYouGo = this.permissionService.canGo(expetedGroup);
        } else {
            canYouGo = true;
        }
        if (currentUser && canYouGo) {
            // logged in so return true
            return true;
        } else {
            // not logged in so redirect to login page with the return url
            // , { queryParams: { returnUrl: state.url } }
            this.router.navigate(['']);
            return false;
        }

    }
}
