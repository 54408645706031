import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient) { }

  updateHistory(body: any) {
    return this.http.patch(`${environment.stgUrl}/api/profile/my-history`, body);
  }
}
