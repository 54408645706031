import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { ReleasesService } from '@core/_services/releases.service';
import { HomeService } from '@core/_services/home.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '@env/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public titleTxt = "Home - Intranet Honda"
  public description = ""
  public featureImg = ''

  constructor(
    private router: Router,
    private meta: Meta,
    private title: Title,
    private releasesServices: ReleasesService,
    private homeServices: HomeService,
    public sanitizer: DomSanitizer
  ) {
    this.title.setTitle(this.titleTxt);
    // Añadir el tag de la info de la página
    this.meta.addTags([
      { name: 'description', content: this.description },
      { name: 'og:title', content: this.titleTxt },
      { name: 'og:image', content: this.featureImg },
      { name: 'og:url', content: this.router.url },
      { name: 'og:description', content: this.description },
      { name: 'twitter:title', content: this.titleTxt },
      { name: 'twitter:description', content: this.description },
      { name: 'twitter:image', content: this.featureImg }
    ]);
  }

  today = new Date();
  year = this.today.getFullYear();
  releases: any;
  releaseMain: any;
  home: number = 1;
  homeData: any;
  video: any;
  selfAdvertise: any;
  urlDev: any;

  transform(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit() {
    this.urlDev = environment.stgUrl;

    this.releasesServices.getReleaseHome(this.year, this.home).subscribe((data: any) => {
      this.releases = data;
      if (data.length > 0) {
        this.releaseMain = data[0];
      }
    });
    this.homeServices.getHomeData().subscribe((data: any) => {
      this.video = data.video;
      this.video = this.sanitizer.bypassSecurityTrustResourceUrl(data.video);
      this.selfAdvertise = data.self_advertise;
    });
  }

}
