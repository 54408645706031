import { Component, OnInit } from '@angular/core';
import { CartService } from '@core/pop/services/cart/cart.service';
import { OrdersService } from '@core/pop/services/orders/orders.service';
import { ProductsService } from '@core/pop/services/products/products.service';
import { PermissionsService } from '@core/_helpers/permissions.service';
import { AuthenticationService } from '@core/_services/authentication.service';

@Component({
  selector: 'app-header-pop',
  templateUrl: './header-pop.component.html',
  styleUrls: ['./header-pop.component.scss']
})
export class HeaderPopComponent implements OnInit {

  public stateMenu: boolean = false;
  public userActive: boolean = false;
  public accessActive: boolean = false;
  user: any;
  cart: any;
  userType: string = 'user';
  url: any;
  canSee: boolean;

  constructor(private permissionsService: PermissionsService, private authService: AuthenticationService, private cartService: CartService) { }

  ngOnInit(): void {
    this.authService.currentUser.subscribe((data: any) => {
      this.user = data;
    });

    this.cartService.currentItems.subscribe((data: any) => {
      this.cart = data;
    });

    let data = localStorage.getItem('user');
    let groups = JSON.parse(data).groups;

    groups.forEach((e: any) => {
      if (e === 'intranet' || e === 'manual_de_marca' || e === 'toolkit') {
        this.userType = 'user'
        this.canSee = true;
      } else if (e === 'manual_de_marca_admin' || e === 'toolkit_admin') {
        this.userType = 'admin'
        this.canSee = true;
      } else {
        this.canSee = false;
      }
    });

    this.ifAdminMdm();
    this.ifUserMdm();
  }

  ifAdminMdm() {
    if(this.userType === 'admin') {
      this.url = 'admin'
    }
  }

  ifUserMdm() {
    if(this.userType === 'user') {
      this.url = 'usuario'
    }
  }

  accessActiveF() {
    this.accessActive = !this.accessActive;
    const that = this;

    window.onclick = function(event: any) {

      if(!event.target.matches('.user-access')){
        that.accessActive = false;
      }
    }

  }

  userActiveF() {
    this.userActive = !this.userActive;
    const that = this;

    window.onclick = function(event: any) {

      if(!event.target.matches('.user-name')){
        that.userActive = false;
      }
    }

  }

  openMenu(id: any) {
    var element = document.getElementById(id)
    if(element.classList.contains("open-menu")){
      element.classList.remove("open-menu")
    } else {
      element.classList.add("open-menu")
    }
  }

  canGoIntranet() {
    return this.permissionsService.canGo('intranet');
  }

  openSidenav() {
    this.stateMenu = !this.stateMenu;
  }

}
