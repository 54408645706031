import { Component } from "@angular/core";

@Component({
    selector: 'pop-root',
    templateUrl: './pop.component.html',
    styleUrls: ['./pop.component.scss']
})

export class PopComponent {

    constructor() {}

    ngOnInit(): void {}

}