import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePopComponent } from './home-pop/home-pop.component';
import { RouterModule } from '@angular/router';
import { ProfilePopComponent } from './profile-pop/profile-pop.component';
import { HeaderPopComponent } from './shared/header-pop/header-pop.component';
import { FooterPopComponent } from './shared/footer-pop/footer-pop.component';
import { PopRoutingModule } from './pop-routing.module';
import { PopComponent } from './pop.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { NewOrderComponent } from './new-order/new-order.component';
import { DetailOrderComponent } from './detail-order/detail-order.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { SuccessfulOrderComponent } from './successful-order/successful-order.component';
import { CustomselectComponent } from './shared/customselect/customselect.component';
import { VerifyCartComponent } from './verify-cart/verify-cart.component';
import { SliderComponent } from './shared/slider/slider.component';
import { FloatingBtnComponent } from './floating-btn/floating-btn.component';
import { ModalComponent } from './modal/modal.component';


@NgModule({
  declarations: [
    HomePopComponent,
    ProfilePopComponent,
    HeaderPopComponent,
    FooterPopComponent,
    PopComponent,
    MyOrdersComponent,
    NewOrderComponent,
    DetailOrderComponent,
    OrderSummaryComponent,
    SuccessfulOrderComponent,
    CustomselectComponent,
    VerifyCartComponent,
    SliderComponent,
    FloatingBtnComponent,
    ModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PopRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    CustomselectComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class PopModule { }
