import { Component, OnInit } from '@angular/core';
import { ImagesService } from '../../services/images/images.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  constructor(private imgService: ImagesService) { }

  data: any;

  ngOnInit(): void {
    this.imgService.getImages().subscribe((data: any) => {
      this.data = data;
    });
  }

}
