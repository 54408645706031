import { Component, OnInit } from '@angular/core';
import { ImagesService } from '@core/_services/images.service';
import { ReleasesService } from '@core/_services/releases.service';
import { CustomselectComponent } from '@core/shared/customselect/customselect.component';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from '@env/environment';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  today = new Date();
  year = this.today.getFullYear();
  yearsIn: any = [];
  years: [] = [];
  news: [] = [];
  banner: any;
  monthsFB: any;
  selectActive: boolean = true;
  urlEnv = environment.stgUrl;
  newsYears: string[] = [];
  newsMonths: string[] = [];
  sessionNews: any;
  dates: any;

  filterForm = new FormGroup({
    year: new FormControl(),
    month: new FormControl()
  });

  constructor(private releasesServices: ReleasesService, private imgService: ImagesService) {}

  ngOnInit(): void {
    this.releasesServices.getDate().subscribe((data: any) => {
      this.dates = data.news;
      this.dates.forEach((data: any) => {
        this.newsYears.push(data.year);
      });
    });
    this.releasesServices.getLatestNews().subscribe((data: any) => {
      this.news = data;
    });
    this.imgService.getImages().subscribe((data: any) => {
      this.banner = data;
    });
  }

  getNewsMont(month: number, event: any) {
    // console.dir(document.getElementsByClassName('month'));
    let months = Array.from(document.getElementsByClassName('month'));
    months.forEach((element: any) => {
      element.classList.remove('active');
    });
    this.releasesServices.getNewsByMonth(this.year, month).subscribe((data: any) => {
      this.news = data;
      event.target.parentNode.parentNode.classList.add('active');
    });
  }

  onChangeYear(event: any){
    this.year = this.filterForm.get('year').value;
    this.releasesServices.getNews(this.year).subscribe((data: any) => {
      this.news = data;
    });
    this.dates.forEach((data: any) => {
      if (data.year == this.year) {
        this.newsMonths = data.months;
      }
    });
  }

  onChangeMonth(event: any) {
    this.releasesServices.getNewsByMonth(this.year, this.filterForm.get('month').value).subscribe((data: any) => {
      this.news = data;
    });
  }

}
