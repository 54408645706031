import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CartService } from '../services/cart/cart.service';
import { ProductsService } from '../services/products/products.service';
import { ImagesService } from '../services/images/images.service';

@Component({
  selector: 'app-successful-order',
  templateUrl: './successful-order.component.html',
  styleUrls: ['./successful-order.component.scss']
})
export class SuccessfulOrderComponent implements OnInit {

  orderId: number;
  cartProducts: any;
  cartTotal: any;
  banner: any;

  constructor(private route: ActivatedRoute, private productsService: ProductsService, private cartServices: CartService, private imgService: ImagesService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.orderId = params['id'];
    });

    this.productsService.getCart().subscribe((data: any) => {
      //
    });

    this.cartServices.currentItems.subscribe((data: any) => {
      this.cartProducts = data.cart;
      this.cartTotal = data.total;
    });

    this.imgService.getImages().subscribe((data: any) => {
      this.banner = data;
    });
  }

}
